.ui-switch {
  display: flex;

  .form-check-input {
    opacity: 1 !important;
    visibility: visible !important;
    position: relative !important;
    margin-left: 0;
    width: 3em;
    height: 1.5em;
  }
  .form-check-label {
    padding-left: 10px;
    line-height: 1.5em;
    padding-top: 0.25em;
  }
}
