.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .copyright {
    font-size: 0.9em;
    line-height: 1.8;
  }
  .dot {
    display: inline-block;
    margin-right: 6px;
    width: 7px;
    height: 7px;
    background-color: black;
    border-radius: 100px;
  }
}
