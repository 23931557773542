.invitation-register,
.login {
  .logo-img {
    text-align: center;
    border-bottom: 1px solid lightgray;
    margin-bottom: 10px;
    padding-bottom: 15px;

    img {
      max-height: 100px;
    }
  }

  .card {
    padding: 10px 10px;
  }

  .btn {
    height: 33px;
  }
}
