.navbar-absolute {
  .navbar-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .user-dropdown {
    overflow: visible;
    .dropdown-menu {
      background-color: white !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      border: 0.5px solid lightgray !important;

      .role {
        font-size: 12px;
        color: #51cbce;
      }
    }
    .button-container {
      text-align: right;
      width: 200px;
      .button {
        cursor: pointer;
        padding-top: 2px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        color: gray;
        /*
        border: 1px solid gray;
        border-radius: 5px;
				padding: 3px 15px;
				*/
        margin-left: 100px;
        .icon {
          margin-top: auto;
          margin-bottom: auto;
        }
        .username {
          padding-top: 3px;
          padding-left: 5px;
          font-size: 13px;
          font-weight: bold;
        }
      }
      .button:hover {
        color: #34b5b8;
      }
    }
    .dropdown-header {
      font-size: 13px;
      text-transform: unset;
    }
  }
}
