.notifications-container {
  position: fixed;
  z-index: 1000;
  right: 0;
  bottom: 0;
  padding: 10px 10px;

  .toast-body {
    font-size: 13px;
  }

  .toast {
    margin-top: 5px;
  }
}
