body {
  font-size: 12px;
}

body.modal-open {
  background-color: #f4f3ef;
  // padding-right: 0 !important;
  overflow: hidden;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 5px 20px;
  text-transform: none;
}

.table > thead > tr > th {
  font-size: inherit;
}

.dropdown-menu .dropdown-item {
  font-size: 12px;
}

.multi-select-wrapper {
  min-width: 200px;
}

.logo:after {
  display: none;
}

.main-panel > .content {
  padding: 0 22px 0;
  min-height: calc(100vh - 53px - 42px);
  margin-top: 53px;
  padding-top: 20px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .card-stats [class*='col-'] .statistics::after {
    display: none;
  }
  .main-panel > .content {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
  }
}

.footer {
  height: 42px;
  padding: 10px 0;
  border-top: 1px solid #ddd;
}

.sidebar .nav li > a,
.off-canvas-sidebar .nav li > a {
  font-size: 12px;
  line-height: 25px;
  margin: 0px 15px 0;
}

.svg-inline--fa.fa-fw {
  width: 0.9em;
}

.main-panel {
  width: calc(100% - 220px);
}

@media screen and (max-width: 991px) {
  .main-panel {
    width: 100%;
  }
  .nav-open .main-panel {
    right: 0;
    transform: translate3d(220px, 0, 0);
  }
}

.sidebar .sidebar-wrapper,
.off-canvas-sidebar .sidebar-wrapper {
  width: 220px;
}

.sidebar,
.off-canvas-sidebar {
  width: 220px;
}

.sidebar .nav,
.off-canvas-sidebar .nav {
  width: 218px;
}

.ps {
  overflow: initial !important;
}

.btn {
  height: 28px;
  padding: 8px 16px;
  border-radius: 5px;
}

.form-control,
.input-group .form-control,
.form-select,
select.form-control:not([size]):not([multiple]) {
  padding: 6px 8px 6px 8px;
  font-size: 12px;
  height: 33px;
}

.form-select,
.rw-widget > .rw-widget-container {
  height: 33px;
  color: #555 !important;
}

.btn-close {
  border: none;
  box-shadow: none !important;
}

.form-check-input:focus,
.form-select:focus,
.rw-state-focus > .rw-widget-container {
  border: 1px solid #9a9a9a !important;
  box-shadow: none !important;
  outline: 0 !important;
  color: #66615b !important;
}

.table > :not(:first-child) {
  border-top: 1px solid lightgray;
}

button[class*='btn-outline-'] {
  padding: 7px 16px 7px 16px;
  border-width: 1px !important;
}

.btn-outline-primary:focus:not(:hover) {
  background-color: transparent !important;
  color: #51cbce !important;
}

.btn-outline-danger:focus:not(:hover) {
  background-color: transparent !important;
  color: #ef8157 !important;
}

a {
  text-decoration: none;
}

.nav-tabs {
  .nav-link {
    font-weight: bold;
    color: gray;
  }
}

.navbar-toggler:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none;
}

.card .card-body {
  padding-top: 10px;
  padding-bottom: 0px;
}

.card {
  border-radius: 5px;
}

.modal-body {
  padding-bottom: 0px;
}

.modal-footer {
  padding: 5px 1rem;
  border-top: none;
}

.nav-item {
  cursor: pointer;
}

.list-group-item.active {
  background-color: #51cbce;
  border-color: #51cbce;
}

.graph-options {
  width: unset;
}

.graph-options tbody tr td {
  padding: 1px 5px;
}

.graph-options tbody tr td select {
  padding: 4px 8px 4px 8px !important;
  height: 26px !important;
}

.time-options tbody tr td {
  padding: 1px 5px;
  min-width: 110px;
}

.time-options tbody tr td select {
  padding: 4px 8px 4px 8px !important;
  height: 26px !important;
}

.time-options tbody tr td .rw-widget > .rw-widget-container {
  height: 26px !important;
}

.time-options tbody tr td .rw-widget-input {
  box-shadow: unset;
}

.data-query-body {
  overflow: auto;
  height: calc(100% - 30px);
}

.dataQuery-body-options {
  padding: 10px 10px 0px 10px;
}

.dropdown-menu .dropdown-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.dropdown-menu .dropdown-item:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dropdown-menu {
  border-radius: 5px;
}

.disabled.dropdown-item {
  color: #bdbdbc !important;
}

.action-bar {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;

  .btn {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.role-badge-master {
  background-color: #6f42c1 !important;
}
.role-badge-admin {
  background-color: #fd7e14 !important;
}
.role-badge-user {
  background-color: #0dcaf0 !important;
}
